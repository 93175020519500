<template>
  <div class="school">
    <div class="content">
      <div class="head card flex">
        <van-image round fit="cover" :src="logo">
          <template v-slot:error>暂无校徽</template>
        </van-image>

        <div class="inner flex column between">
          <div class="name van-ellipsis">{{ name }}</div>

          <div>
            <van-tag
              v-for="item in tags"
              :key="item"
              type="primary"
              size="medium"
              round
              >{{ item }}</van-tag
            >
          </div>
        </div>
      </div>

      <div class="text card">
        <div class="title">
          <van-icon :name="require('@/assets/images/jianjie.png')"></van-icon>
          学校简介
        </div>

        <div class="detail" :style="{ height }">
          <div class="detail-content" v-html="introduce"></div>
        </div>

        <div class="toggle flex middle center" @click="toggleDetail">
          <van-icon :name="isHide ? 'arrow-down' : 'arrow-up'" />
          {{ isHide ? '展开' : '收起' }}
        </div>
      </div>

      <div class="card">
        <div class="title">
          <van-icon :name="require('@/assets/images/shengyuan.png')"></van-icon>
          毕业生源
        </div>
        <van-image class="students" :src="students" @click="previewStudents">
          <template v-slot:error>学校还未上传毕业生源</template>
        </van-image>
      </div>
    </div>
  </div>
</template>

<script>
import { querySchoolInfo } from '@/api/api';
import { ImagePreview, Image, Tag, Icon } from 'vant';

export default {
  name: 'School',

  data() {
    return {
      isHide: true,
      height: '0',
      logo: '',
      name: '',
      tags: [],
      students: '',
      introduce: '',
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      querySchoolInfo({
        params: {
          schoolId: this.$route.query.id,
        },
        loading: true,
      }).then((res) => {
        this.logo = res.logoUrl || '';
        this.name = res.name || '';
        this.tags = [];
        if (res.cityName) {
          this.tags.push(res.cityName);
        }
        if (res.typeName) {
          this.tags.push(res.typeName);
        }
        this.introduce = this.disposeIntroduce(res.introduce) || '';
        this.students = res.graduatesSourceUrl || '';
        this.$nextTick(this.calcHeight);
      });
    },

    disposeIntroduce(content) {
      return content
        .replace(/</gi, '&lt;')
        .replace(/>/gi, '&gt;')
        .replace(/[\r\n]/gi, '<br/>')
        .replace(/ /gi, '&nbsp;');
    },

    toggleDetail() {
      this.isHide = !this.isHide;
      this.calcHeight();
    },

    calcHeight() {
      const height = document.querySelector('.detail-content').scrollHeight;

      if (this.isHide) {
        this.height = height < 134 ? height + 'px' : '134px';
      } else {
        this.height = height + 'px';
      }
    },

    previewStudents() {
      if (this.students) {
        ImagePreview([this.students]);
      }
    },
  },

  components: {
    [Image.name]: Image,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
  },
};
</script>

<style lang="scss" scoped>
.school {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  overflow: auto;
}

.content {
  width: 100%;
  font-size: 12px;
}

.head {
  .van-image {
    flex: 0 0 auto;
    margin-right: 20px;
    width: 4.5em;
    height: 4.5em;
  }

  .inner {
    flex: 1;
    overflow: hidden;

    .name {
      font-size: 1.5em;
    }

    .van-tag {
      background: #f6f6f6;
      color: #666666;
      font-size: 1em;
      padding: 0.3125em 0.75em;
      margin-right: 5px;
    }
  }
}

.title {
  display: inline-flex;
  padding: 0 1em;
  background: #fd4c5b;
  color: #fff;
  height: 2.2em;
  line-height: 2.2em;
  border-radius: 1.1em;
  align-items: center;

  .van-icon {
    margin-right: 0.45em;
  }
}

.detail {
  margin-top: 0.8em;
  overflow: hidden;
  height: 8.3em;
  transition: all 0.5s ease-in-out;
}

.text.card {
  overflow: hidden;
}

.toggle {
  background: #fff;
  margin-top: 18px;
  color: #ccc;
  text-align: center;
  cursor: pointer;

  .van-icon {
    margin-right: 2px;
  }
}

.students {
  display: block;
  margin: -18px;
  margin-top: 0.8em;
  min-height: 40px;
}

@media screen and (min-width: 560px) {
  .content {
    width: 560px;
    margin: 0 auto;
    font-size: 16px;
  }
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.middle {
    align-items: center;
  }
}

.card {
  margin: 0.67em 0.83em;
  padding: 18px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 12px #ebedf0;
}

:deep(.van-image__error) {
  font-size: 0.8em;
}
</style>
